<template>
  <div
    class="row-background cursor-pointer"
    :style="{ background: rowBackground }"
    @click="openResultsPage(result)"
  >
    <div class="race-color" :style="{ background: calculateStyle }"></div>
    <div class="r-table-row">
      <div class="race-data">
        <div class="race-title">
          <div class="race-date">
            <span>{{ convertDate }}</span>
            <span v-if="result.normalized_heat">{{ heat }}</span>
            <span class="result-category"> {{ result.event_category }} </span>
          </div>
          <div class="race-name">{{ result.event_name }}</div>
        </div>
        <div class="race-type">
          <div class="photo-count">
            <i class="material-icons">photo_library</i>
            <span class="count-value">{{ result.num_photos || "--" }}</span>
          </div>
        </div>
      </div>
      <div
        class="race-results"
        :class="{
          'results-processing': showResultsSection,
          'has-photos': result.num_photos,
        }"
      >
        <div class="result-item time">
          <div v-if="result.completion_time">
            {{ convertTime }}
          </div>
          <div v-if="!result.completion_time">{{ $t("dq") }}</div>
          <div class="result-name">{{ $t("time") }}</div>
        </div>
        <div class="result-item overall" v-if="!showResultsSection">
          <span class="user-result">{{
            result.rank_overall ? result.rank_overall : "--"
          }}</span>
          <span class="total-result"
            >/{{ result.count_overall ? result.count_overall : "--" }}
          </span>
          <div class="result-name">{{ $t("overall") }}</div>
        </div>
        <div class="result-item gender" v-if="!showResultsSection">
          <span class="user-result">{{
            result.rank_gender ? result.rank_gender : "--"
          }}</span>
          <span class="total-result"
            >/{{ result.count_gender ? result.count_gender : "--" }}
          </span>
          <div class="result-name">{{ $t("by_gender") }}</div>
        </div>
        <div class="result-item age" v-if="!showResultsSection">
          <span class="user-result">{{
            result.rank_age_group ? result.rank_age_group : "--"
          }}</span>
          <span class="total-result"
            >/{{ result.count_age_group ? result.count_age_group : "--" }}
          </span>
          <div class="result-name">{{ $t("age") }}</div>
        </div>
        <div
          class="result-item photo-count"
          v-if="!showResultsSection"
        >
          <i class="material-icons">photo_library</i>
          <span class="count-value">{{ result.num_photos || "--" }}</span>
        </div>
        <div class="result-item processing-text" v-if="showResultsSection">
          <span>{{ $t("results_processing") }}</span>
        </div>
      </div>
    </div>
    <i class="material-icons mobile-arrow">keyboard_arrow_right</i>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data: () => ({}),
  computed: {
    isEven() {
      return this.index % 2 == 0;
    },
    rowBackground() {
      if (this.isEven) return "rgba(255, 255, 255, 0.1)";
      return "#020000";
    },
    calculateStyle() {
      let color = "#2361A7";
      if (!this.result.event_category) return color;

      switch (this.result.event_category.toUpperCase()) {
        case "SPRINT":
          color = "#CF1019";
          break;
        case "SUPER":
          color = "#2361A7";
          break;
        case "BEAST":
          color = "#84BD00";
          break;
        case "ULTRA":
          color = "#8F34C1";
          break;
        case "KIDS":
          color = "#F4741D";
          break;
        case "STADION":
          color = "#FFDE00";
          break;
        case "STADIUM SPRINT":
          color = "#FFDE00";
          break;
        case "TRAIL":
          color = "#6DA286";
          break;
        case "HURRICANE HEAT":
          color = "#012756";
          break;
        case "HH 12HR":
          color = "#012756";
          break;
        case "AGOGE":
          color = "#191818";
          break;
      }
      return color;
    },
    convertDate() {
      if (!this.result.event_date) return "";
      return moment(this.result.event_date).format("MMM DD, YYYY");
    },
    heat() {
      if (!this.result.normalized_heat) return "";
      return this.result.normalized_heat.replace("_", " ");
    },
    showResultsSection() {
      return (
        !this.result.rank_overall &&
        !this.result.count_overall &&
        !this.result.rank_gender &&
        !this.result.count_gender &&
        !this.result.rank_age_group &&
        !this.result.count_age_group
      );
    },
    convertTime() {
      let time = this.result.completion_time;
      let msIndex = time.indexOf("."),
        t;
      if (msIndex !== -1) t = time.substring(0, time.indexOf("."));
      else t = time;
      return t;
    },
    hasPhoto() {
      return this.result.has_photos;
    },
  },
  props: ["index", "result"],
  methods: {
    openResultsPage(result) {
      this.$router.push({
        name: "resultPage",
        params: { eventId: result.event_id, id: result.result_id },
      });
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.row-background {
  height: 90px;
  padding: 2px 0;
  display: flex;
  flex-direction: row;
  position: relative;
  .race-color {
    width: 2px;
    height: 100%;
    margin-right: 45px;
  }
  .r-table-row {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-right: 40px;
    .race-data {
      display: grid;
      grid-template-columns: 280px 1fr;
      grid-column-gap: 10px;

      .race-title {
        font-size: 14px;
        font-weight: bold;
        line-height: 17px;
        letter-spacing: 0.0025em;
        text-transform: capitalize;

        .race-date {
          margin: 18px 0 4px 0;
          font-size: 12px;
          font-weight: 600;
          line-height: 15px;
          color: #9d9d9d;
          letter-spacing: 0.02em;
          text-transform: uppercase;
          position: relative;
          i {
            display: none;
          }
          span {
            margin-right: 13px;
          }
        }
        .race-name {
          height: 36px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
    .race-type {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      font-size: 14px;
      font-weight: 900;
      line-height: 18px;
      font-style: italic;
      text-transform: uppercase;
      padding-left: 10px;

      i {
        color: #9d9d9d;
        font-size: 24px;
      }
      .photo-count {
        display: none;

        .count-value {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 1.2;
          margin-left: 10px;
          white-space: nowrap;
        }
      }

      @media screen and (min-width: 901px) {
        .photo-count {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .race-results {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      align-items: center;
      font-size: 14px;
      line-height: 17px;
      font-weight: bold;
      text-align: end;
      .result-item {
        .result-name {
          font-size: 12px;
          font-weight: 600;
          line-height: 15px;
          color: #9d9d9d;
          letter-spacing: 0.02em;
          text-transform: uppercase;
          display: none;
        }
      }
      .user-result {
      }
      .total-result {
        color: #9d9d9d;
        font-weight: 500;
      }

      .photo-count {
        display: none;

        i {
          color: #9d9d9d;
          // font-size: 18px;
        }

        .count-value {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 1.2;
          margin-left: 10px;
          white-space: nowrap;
        }
      }

      @media screen and (max-width: 900px) {
        .photo-count {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        .age {
          display: none;
        }
      }
    }
    .results-processing {
      grid-template-columns: 1fr 3fr;
      gap: 10px;
      .processing-text {
        color: #9d9d9d;
        font-weight: bold;
        text-align: center;
        font-style: italic;
        span {
          // margin-left: 28px;
        }
      }
    }
  }
  .mobile-arrow {
    display: none;
    position: absolute;
    top: calc(50% - 15px);
    right: 15px;
    font-size: 30px;
    color: #9d9d9d;
  }
}
.row-background:hover {
  background: rgba(255, 255, 255, 0.16) !important;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.7) !important;
  .r-table-row {
  }
  .race-color {
    width: 10px;
    transition: 0.2s ease;
    border-radius: 0 2px 2px 0;
    margin-right: 38px;
  }
}
@media screen and (min-width: 1300px) {
  .row-background {
    .r-table-row {
      grid-template-columns: 402px 1fr;
      .race-data {
        grid-template-columns: 275px 1fr;
        grid-column-gap: 25px;
      }
      .race-results {
        grid-template-columns: 1fr 100px 1fr 1fr;
        grid-column-gap: 24px;
      }
      .results-processing {
        grid-template-columns: 1fr 3fr;
        gap: 92px;
        .processing-text {
          span {
            margin-right: 28px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .row-background {
    height: 140px;
    .race-color {
      margin-right: 27px;
    }
    .r-table-row {
      grid-template-columns: 1fr 170px;
      margin-right: 20px;
      .race-data {
        grid-template-columns: minmax(100px, 250px) 1fr;
        .race-title {
          .race-date {
            margin: 16px 0 4px 0;
          }
        }
      }
      .race-type {
        align-items: flex-start;
        margin-top: 16px;
        @media screen and (max-width: 500px) {
          display: none;
        }
      }
      .race-results {
        grid-template-columns: 1fr 1fr;
        .result-item {
          text-align: start;
          .result-name {
            display: grid;
          }
        }
      }
    }
  }
  .row-background:hover {
    .r-table-row {
    }
    .race-color {
      margin-right: 20px;
    }
  }
}
@media screen and (max-width: 499px) {
  .row-background {
    height: 155px;
    max-width: calc(100% - 50px);
    padding: 0;
    margin: 0 auto;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.1) !important;
    .race-color {
      margin-right: 20px;
      width: 6px;
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
    }
    .r-table-row {
      grid-template-rows: 1fr 34px;
      grid-template-columns: 1fr;
      margin-right: 0;
      padding-bottom: 30px;
      .race-data {
        grid-template-columns: 1fr;
        .race-title {
          .race-date {
            margin: 25px 30px 4px 0;
            font-size: 11px;
            i {
              display: block;
              position: absolute;
              right: 0;
              bottom: 0;
              font-size: 18px;
            }
          }
          .race-name {
            max-width: calc(100% - 50px);
            font-size: 16px;
            line-height: 1.2;
            // height: 72px;
            height: 54px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }
      .race-type {
        margin-top: 0;
        line-height: 24px;
        justify-content: flex-start;
      }
      .race-results {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 25px;
        .result-item {
          .result-name {
            display: grid;
            font-size: 11px;
          }
        }

        .gender {
          display: none;
        }
      }
      .results-processing {
        grid-template-columns: 2fr 2fr;
        .processing-text {
          span {
            // margin-left: 0;
          }
        }
      }
    }
    .mobile-arrow {
      display: block;
    }
    & + .row-background {
      margin-top: 12px;
    }
  }
  .row-background:hover {
    .r-table-row {
    }
    .race-color {
      margin-right: 20px;
      width: 4px;
    }
  }
}
@media screen and (max-width: 375px) {
  .row-background {
    height: 155px;
    max-width: calc(100% - 30px);
    padding: 0;
    margin: 0 auto;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.1) !important;
    .race-color {
      margin-right: 14px;
      width: 6px;
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
    }
    .r-table-row {
      grid-template-rows: 1fr 34px;
      grid-template-columns: 1fr;
      margin-right: 0;
      padding-bottom: 30px;
      .race-data {
        grid-template-columns: 1fr;
        .race-title {
          .race-date {
            margin: 25px 30px 5px 0;
            font-size: 11px;
            i {
              display: block;
              position: absolute;
              right: 0;
              bottom: 0;
              font-size: 18px;
            }
          }
          .race-name {
            max-width: calc(100% - 50px);
            font-size: 16px;
            line-height: 1.2;
            // height: 72px;
            height: 54px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }
      .race-type {
        margin-top: 0;
        line-height: 24px;
        justify-content: flex-start;
      }
      .race-results {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 25px;
        .result-item {
          .result-name {
            display: grid;
            font-size: 11px;
          }
        }
        .gender {
          display: none;
        }
      }
      .results-processing {
        grid-template-columns: 2fr 2fr;
        .processing-text {
          span {
            // margin-left: 0;
          }
        }
      }
    }
    .mobile-arrow {
      display: block;
      right: 10px;
    }

    & + .row-background {
      margin-top: 12px;
    }
  }
  .row-background:hover {
    .r-table-row {
    }
    .race-color {
      margin-right: 20px;
      width: 4px;
    }
  }
}
</style>
