var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mem-view results-view"},[_c('div',{staticClass:"mem-title"},[_vm._v(_vm._s(_vm.$t("results") + " & " + _vm.$t("Photos")))]),_c('lifetime-stats',{attrs:{"stats":_vm.userStats}}),_c('div',{staticClass:"mem-subtitle"},[(_vm.loading)?_c('div',[_vm._v(_vm._s(_vm.$t("loading_results") + "..."))]):_vm._e(),(!_vm.loading)?_c('div',[_vm._v(_vm._s(_vm.$t("results_and_photos")))]):_vm._e()]),(_vm.haveResults)?_c('div',{staticClass:"table-filters"},[_c('transition',{attrs:{"name":"slide-x-transition","mode":"out-in"}},[(_vm.showFilters)?_c('div',{staticClass:"filtes-sidebar",style:({ width: `${_vm.sidebarWidth}` })},[_c('sidebar-filters',{attrs:{"filtersType":'resultsList'},on:{"close-sidebar":_vm.closeFilters,"apply-filters":_vm.applySort}})],1):_vm._e()]),_c('transition',{attrs:{"name":"fade-transition","mode":"out-in"}},[(_vm.showFilters)?_c('div',{staticClass:"filters-overlay",on:{"click":function($event){return _vm.closeFilters()}}}):_vm._e()]),_c('div',{staticClass:"filter-btn cursor-pointer",on:{"click":function($event){return _vm.openFilters()}}},[_c('span',[_vm._v(_vm._s(_vm.$t("filters")))])])],1):_vm._e(),(_vm.haveResults)?_c('div',{staticClass:"table-header"},[_c('div',{staticClass:"t-header-data"},[_c('div',[_vm._v(_vm._s(_vm.$t("event")))])]),_c('div',{staticClass:"t-header-results"},[_c('div',{staticClass:"sort-button cursor-pointer",class:{ 'is-active': _vm.sort.sortBy === 'completion_time' },on:{"click":function($event){return _vm.sortBy('completion_time')}}},[(
            _vm.sort.sortBy === 'completion_time' && _vm.sort.sortDirection === 'desc'
          )?_c('i',{staticClass:"material-icons"},[_vm._v("arrow_downward")]):_vm._e(),(
            _vm.sort.sortBy === 'completion_time' && _vm.sort.sortDirection === 'asc'
          )?_c('i',{staticClass:"material-icons"},[_vm._v("arrow_upward")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.$t("time")))])]),_c('div',{staticClass:"sort-button cursor-pointer",class:{ 'is-active': _vm.sort.sortBy === 'rank_overall' },on:{"click":function($event){return _vm.sortBy('rank_overall')}}},[(
            _vm.sort.sortBy === 'rank_overall' && _vm.sort.sortDirection === 'desc'
          )?_c('i',{staticClass:"material-icons"},[_vm._v("arrow_downward")]):_vm._e(),(
            _vm.sort.sortBy === 'rank_overall' && _vm.sort.sortDirection === 'asc'
          )?_c('i',{staticClass:"material-icons"},[_vm._v("arrow_upward")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.$t("overall")))])]),_c('div',{staticClass:"sort-button cursor-pointer",class:{ 'is-active': _vm.sort.sortBy === 'rank_gender' },on:{"click":function($event){return _vm.sortBy('rank_gender')}}},[(
            _vm.sort.sortBy === 'rank_gender' && _vm.sort.sortDirection === 'desc'
          )?_c('i',{staticClass:"material-icons"},[_vm._v("arrow_downward")]):_vm._e(),(_vm.sort.sortBy === 'rank_gender' && _vm.sort.sortDirection === 'asc')?_c('i',{staticClass:"material-icons"},[_vm._v("arrow_upward")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.$t("by_gender")))])]),_c('div',{staticClass:"sort-button cursor-pointer",class:{ 'is-active': _vm.sort.sortBy === 'rank_age_group' },on:{"click":function($event){return _vm.sortBy('rank_age_group')}}},[(
            _vm.sort.sortBy === 'rank_age_group' && _vm.sort.sortDirection === 'desc'
          )?_c('i',{staticClass:"material-icons"},[_vm._v("arrow_downward")]):_vm._e(),(
            _vm.sort.sortBy === 'rank_age_group' && _vm.sort.sortDirection === 'asc'
          )?_c('i',{staticClass:"material-icons"},[_vm._v("arrow_upward")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.$t("age")))])])])]):_vm._e(),(!_vm.loading && _vm.haveResults)?_c('div',{staticClass:"results-table",style:({ opacity: _vm.isLoading ? '0.5' : '1' })},[_vm._l((_vm.sortedResultsList),function(result,index){return [_c('result-table-row',{key:index,attrs:{"index":index,"result":result}})]})],2):_vm._e(),(_vm.loading)?_c('div',{staticClass:"table-loading"},[_vm._m(0),_c('div',{staticClass:"loading-line"}),_vm._m(1)]):_vm._e(),(!_vm.loading && _vm.userResults.length === 0)?_c('div',{staticClass:"table-no-results"},[_c('div',{staticClass:"no-results-text"},[_vm._v(_vm._s(_vm.$t("no_results")))]),_c('div',{staticClass:"delta-section"})]):_vm._e(),(!_vm.loading && _vm.haveResults)?_c('div',{staticClass:"table-pagination"},[_c('div',{staticClass:"pagination-info"},[_vm._v(" "+_vm._s(_vm.pagination.offset === 0 ? 1 : _vm.pagination.offset)+" - "+_vm._s(_vm.pageItemsCount)+" "+_vm._s(_vm.$t("of"))+" "+_vm._s(_vm.pagination.total)+" ")]),_c('div',[_c('v-btn',{attrs:{"disabled":_vm.pagination.offset === 0,"icon":"","dark":"","color":"#ffffff"},on:{"click":_vm.previousPage}},[_c('v-icon',[_vm._v("keyboard_arrow_left")])],1),_c('v-btn',{attrs:{"disabled":_vm.pageItemsCount === _vm.pagination.total,"icon":"","dark":"","color":"#ffffff"},on:{"click":_vm.nextPage}},[_c('v-icon',[_vm._v("keyboard_arrow_right")])],1)],1)]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loading-line"},[_c('div',{staticClass:"animated"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loading-line"},[_c('div',{staticClass:"animated"})])
}]

export { render, staticRenderFns }