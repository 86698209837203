<template>
  <div class="mem-view results-view">
    <div class="mem-title">{{ $t("results") + " & " + $t("Photos") }}</div>
    <lifetime-stats :stats="userStats" />
    <!-- total count title -->
    <div class="mem-subtitle">
      <div v-if="loading">{{ $t("loading_results") + "..." }}</div>
      <div v-if="!loading">{{ $t("results_and_photos") }}</div>
    </div>
    <!-- table filters -->
    <div class="table-filters" v-if="haveResults">
      <transition name="slide-x-transition" mode="out-in">
        <div
          class="filtes-sidebar"
          :style="{ width: `${sidebarWidth}` }"
          v-if="showFilters"
        >
          <sidebar-filters
            :filtersType="'resultsList'"
            v-on:close-sidebar="closeFilters"
            v-on:apply-filters="applySort"
          />
        </div>
      </transition>
      <transition name="fade-transition" mode="out-in">
        <div
          class="filters-overlay"
          v-if="showFilters"
          @click="closeFilters()"
        ></div>
      </transition>
      <div class="filter-btn cursor-pointer" @click="openFilters()">
        <span>{{ $t("filters") }}</span>
      </div>
    </div>
    <!-- table header -->
    <div class="table-header" v-if="haveResults">
      <div class="t-header-data">
        <div>{{ $t("event") }}</div>
        <!-- <div class="t-header-type">
          <div>{{ $t("type") }}</div>
        </div> -->
      </div>
      <div class="t-header-results">
        <div
          class="sort-button cursor-pointer"
          :class="{ 'is-active': sort.sortBy === 'completion_time' }"
          @click="sortBy('completion_time')"
        >
          <i
            class="material-icons"
            v-if="
              sort.sortBy === 'completion_time' && sort.sortDirection === 'desc'
            "
            >arrow_downward</i
          >
          <i
            class="material-icons"
            v-if="
              sort.sortBy === 'completion_time' && sort.sortDirection === 'asc'
            "
            >arrow_upward</i
          >
          <span>{{ $t("time") }}</span>
        </div>

        <div
          class="sort-button cursor-pointer"
          :class="{ 'is-active': sort.sortBy === 'rank_overall' }"
          @click="sortBy('rank_overall')"
        >
          <i
            class="material-icons"
            v-if="
              sort.sortBy === 'rank_overall' && sort.sortDirection === 'desc'
            "
            >arrow_downward</i
          >
          <i
            class="material-icons"
            v-if="
              sort.sortBy === 'rank_overall' && sort.sortDirection === 'asc'
            "
            >arrow_upward</i
          >
          <span>{{ $t("overall") }}</span>
        </div>

        <div
          class="sort-button cursor-pointer"
          :class="{ 'is-active': sort.sortBy === 'rank_gender' }"
          @click="sortBy('rank_gender')"
        >
          <i
            class="material-icons"
            v-if="
              sort.sortBy === 'rank_gender' && sort.sortDirection === 'desc'
            "
            >arrow_downward</i
          >
          <i
            class="material-icons"
            v-if="sort.sortBy === 'rank_gender' && sort.sortDirection === 'asc'"
            >arrow_upward</i
          >
          <span>{{ $t("by_gender") }}</span>
        </div>

        <div
          class="sort-button cursor-pointer"
          :class="{ 'is-active': sort.sortBy === 'rank_age_group' }"
          @click="sortBy('rank_age_group')"
        >
          <i
            class="material-icons"
            v-if="
              sort.sortBy === 'rank_age_group' && sort.sortDirection === 'desc'
            "
            >arrow_downward</i
          >
          <i
            class="material-icons"
            v-if="
              sort.sortBy === 'rank_age_group' && sort.sortDirection === 'asc'
            "
            >arrow_upward</i
          >
          <span>{{ $t("age") }}</span>
        </div>
      </div>
    </div>
    <!-- results list -->
    <div
      class="results-table"
      :style="{ opacity: isLoading ? '0.5' : '1' }"
      v-if="!loading && haveResults"
    >
      <template v-for="(result, index) in sortedResultsList">
        <result-table-row :key="index" :index="index" :result="result" />
      </template>
    </div>
    <!-- loading state -->
    <div class="table-loading" v-if="loading">
      <div class="loading-line">
        <div class="animated"></div>
      </div>
      <div class="loading-line"></div>
      <div class="loading-line">
        <div class="animated"></div>
      </div>
    </div>
    <!-- empty state -->
    <div class="table-no-results" v-if="!loading && userResults.length === 0">
      <div class="no-results-text">{{ $t("no_results") }}</div>
      <div class="delta-section"></div>
    </div>
    <!-- table pagination -->
    <div class="table-pagination" v-if="!loading && haveResults">
      <div class="pagination-info">
        {{ pagination.offset === 0 ? 1 : pagination.offset }} -
        {{ pageItemsCount }} {{ $t("of") }} {{ pagination.total }}
      </div>
      <div>
        <v-btn
          :disabled="pagination.offset === 0"
          icon
          dark
          color="#ffffff"
          @click="previousPage"
        >
          <v-icon>keyboard_arrow_left</v-icon>
        </v-btn>
        <v-btn
          :disabled="pageItemsCount === pagination.total"
          icon
          dark
          color="#ffffff"
          @click="nextPage"
        >
          <v-icon>keyboard_arrow_right</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import SidebarFilters from "../components/SidebarFilters.vue";
import LifetimeStats from "../components/LifetimeStats.vue";
import ResultTableRow from "./ResultsListPage/components/ResultTableRow.vue";

export default {
  name: "Results",
  data: () => ({
    isLoading: false,
    loading: true,
    showFilters: false,
    sidebarWidth: "445",
  }),
  computed: {
    ...mapGetters([
      "userResults",
      "userData",
      "specificLinks",
      "pagination",
      "sort",
      "userStats",
    ]),
    sortedResultsList() {
      return _.orderBy(
        this.userResults,
        [this.sort.sortBy],
        [this.sort.sortDirection]
      ).slice(
        this.pagination.offset,
        this.pagination.offset + this.pagination.limit
      );
    },
    haveResults() {
      return this.userResults.length !== 0;
    },
    pageItemsCount() {
      let itemsCount = this.pagination.offset + this.pagination.limit;
      if (itemsCount > this.pagination.total) return this.pagination.total;
      return itemsCount;
    },
  },
  components: {
    SidebarFilters,
    LifetimeStats,
    ResultTableRow,
  },
  methods: {
    ...mapActions(["getUserResults", "addSortParam", "loadMoreRaces"]),
    async nextPage() {
      if (this.pageItemsCount === this.pagination.total) return;
      this.isLoading = true;
      let offset = this.pagination.offset + this.pagination.limit;
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      await this.loadMoreRaces(offset);
      this.isLoading = false;
    },
    async previousPage() {
      if (this.pagination.offset !== 0) {
        this.isLoading = true;
        let offset = this.pagination.offset - this.pagination.limit;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        await this.loadMoreRaces(offset);
        this.isLoading = false;
      }
    },
    async applySort() {
      this.loading = true;
      this.loading = false;
    },
    async sortBy(type) {
      this.addSortParam({
        sortBy: type,
        sortDirection: this.sort.sortDirection,
      });
    },
    toggleSidebar() {
      this.showFilters = !this.showFilters;
    },
    openFilters() {
      if (window.innerWidth <= 500) this.sidebarWidth = "85%";
      else this.sidebarWidth = "445px";

      this.toggleSidebar();
    },
    closeFilters() {
      this.toggleSidebar();
    },
  },
  async mounted() {
    try {
      this.loading = true;
      await this.getUserResults();
    } catch (error) {
      console.log("Can't receive results list. Error:", error);
    }
    this.loading = false;
  },
};
</script>
<style lang="scss" scoped>
.results-view {
  color: #ffffff;
  .mem-title {
    @include elementMarginBottom("M");
  }
  .mem-subtitle {
    @include elementMarginBottom("S");
  }
  .table-filters {
    display: none;
    @media screen and (max-width: 899px) {
      height: 44px;
      margin-bottom: 13px;
      display: flex;
      justify-content: flex-end;
    }
    .filter-btn {
      border-radius: 30px;
      text-transform: uppercase;
      background: rgba(255, 255, 255, 0.1);
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      color: #ffffff;
      padding: 0 23px;
      // display: flex;
      align-items: center;
      display: none;
    }
    .filtes-sidebar {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 999999;
      background: #ffffff;
      width: 0;
    }
    .filters-overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      background: #000000;
      opacity: 0.5;
      z-index: 3;
    }
  }
  .table-header {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: #9d9d9d;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-left: 45px;
    margin-right: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    .t-header-data {
      display: grid;
      grid-template-columns: 280px 1fr;
      grid-column-gap: 10px;
      padding-top: 13px;
      padding-bottom: 15px;
    }
    .t-header-results {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      text-align: end;
      .sort-button {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-top: 13px;
        padding-bottom: 15px;
        position: relative;
        i {
          font-size: 17px;
          margin-right: 10px;
        }

        &.is-active {
          &:after {
            content: "";
            position: absolute;
            width: 95%;
            height: 2px;
            background: white;
            bottom: 0;
            right: -5px;
          }
        }
      }
      .sort-button:hover {
        color: #ffffff;
      }
    }
  }
  .results-table {
  }
  .table-loading {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start !important;
    overflow: hidden;
    .loading-line {
      height: 90px;
      background-color: #020000;
      position: relative;
      overflow: hidden;
    }
    .animated {
      -webkit-animation: loading-line 2s infinite;
      animation: loading-line 2s infinite;
      content: " ";
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      // -webkit-transform: translateX(-100%);
      // transform: translateX(-100%);
      background: linear-gradient(
        110deg,
        rgba(0, 0, 0, 0),
        rgba(255, 255, 255, 0.05),
        rgba(255, 255, 255, 0.15) 70%,
        rgba(255, 255, 255, 0.05),
        rgba(0, 0, 0, 0)
      );
    }
  }
  .table-no-results {
    height: 256px;
    // border: 1px solid #ffffff;
    width: 100%;
    padding-left: 66px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.07);
    .no-results-text {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.0025em;
      opacity: 0.4;
      max-width: 370px;
    }
    .delta-section {
      position: absolute;
      right: -76px;
      background-image: url("../assets/spartan_delta.png");
      // -webkit-background-size: cover;
      // -moz-background-size: cover;
      // -o-background-size: cover;
      background-size: cover;
      // background-size: 80%;
      height: 85%;
      width: 200px;
    }
  }
  .table-pagination {
    display: grid;
    justify-content: end;
    align-items: center;
    grid-template-columns: auto auto;
    grid-column-gap: 40px;
    padding-top: 24px;
    .pagination-info {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.0025em;
      color: #9d9d9d;
    }
  }
  .table-footer {
    padding: 36px 0 0 0;
  }
}
@media screen and (min-width: 1300px) {
  .results-view {
    .table-header {
      grid-template-columns: 402px 1fr;
      .t-header-data {
        grid-template-columns: 250px 1fr;
        grid-column-gap: 62px;
      }
      .t-header-results {
        grid-template-columns: 1fr 100px 1fr 1fr;
        grid-column-gap: 24px;
      }
    }
    .results-table {
    }
  }
}
@media screen and (max-width: 900px) {
  .results-view {
    .table-filters {
      .filter-btn {
        display: flex;
      }
    }
    .table-header {
      display: none;
    }
    .results-table {
    }
    .table-no-results {
      height: 278px;
      .no-results-text {
        max-width: 250px;
      }
      .delta-section {
        height: 60%;
        background-size: 76%;
        right: -108px;
      }
    }
  }
}
@media screen and (max-width: 499px) {
  .results-view {
    .table-filters {
      padding: 0 24px;
      justify-content: start;
      margin-bottom: 29px;
    }
    .table-header {
      display: none;
    }
    .results-table {
    }
    .table-no-results {
      height: 256px;
      .no-results-text {
        max-width: 250px;
      }
      .delta-section {
        // height: 70%;
        background-size: 70%;
        right: -116px;
      }
    }
    .table-pagination {
      padding-right: 40px;
    }
    .table-footer {
      padding: 36px 20px 0 20px;
    }
  }
}
@media screen and (max-width: 375px) {
  .results-view {
    .table-no-results {
      height: 164px;
      .no-results-text {
        font-size: 14px;
        max-width: 180px;
      }
      .delta-section {
        background-size: 44%;
        right: -144px;
      }
    }
  }
}
</style>
