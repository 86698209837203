<template>
  <div class="lifetime-stats">
    <div class="mem-subtitle">{{ $t("lifetime_stats") }}</div>
    <div class="user-stats">
      <div class="stats-section-1">
        <div class="stats-item">
          <div class="stats-subtitle">{{ $t("races") }}</div>
          <div>{{ stats.races ? stats.races : 0 }}</div>
        </div>
        <div class="stats-item">
          <div class="stats-subtitle">trifectas</div>
          <div>{{ stats.trifectas ? stats.trifectas : 0 }}</div>
        </div>
        <div class="stats-item">
          <div class="stats-subtitle">{{ $t("obstacles") }}*</div>
          <div>{{ stats.obstacles ? stats.obstacles : 0 }}</div>
        </div>
      </div>
      <div class="stats-section-2">
        <div class="stats-item">
          <div class="stats-subtitle">{{ $t("distance") }}*</div>
          <div class="stats-value">
            {{ stats.distance ? stats.distance : 0 }}
            <span class="stats-unit">KM</span>
          </div>
        </div>
        <div class="stats-item">
          <div class="stats-subtitle">{{ $t("duration") }}</div>
          <div class="stats-value" v-if="duration">
            <span v-if="duration.days">
              <span>{{ duration.days }}</span>
              <span class="stats-unit">{{ $t("d") }} </span>
            </span>
            <span v-if="duration.hours"
              >{{ duration.hours
              }}<span class="stats-unit">{{ $t("h") }} </span></span
            >
            <span v-if="duration.minutes"
              >{{ duration.minutes
              }}<span class="stats-unit">{{ $t("m") }} </span></span
            >
            <span v-if="duration.seconds"
              >{{ duration.seconds
              }}<span class="stats-unit">{{ $t("s") }} </span></span
            >
          </div>
          <div class="stats-value" v-if="!duration">0</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({}),
  props: ["stats"],
  computed: {
    duration() {
      let seconds = this.stats.duration;
      if (!seconds) return null;
      let d = Math.floor(seconds / (3600 * 24));
      let h = Math.floor((seconds % (3600 * 24)) / 3600);
      let m = Math.floor((seconds % 3600) / 60);
      let s = Math.floor(seconds % 60);

      // var dDisplay = d > 0 ? d + "d" : "";
      // var hDisplay = h > 0 ? h + "h" : "";
      // var mDisplay = m > 0 ? m + "m" : "";
      // var sDisplay = s > 0 ? s + "s" : "";
      return {
        days: d,
        hours: h,
        minutes: m,
        seconds: s,
      };
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.lifetime-stats {
  @include elementMarginBottom("M");

  .mem-subtitle {
    @include elementMarginBottom("S");
  }
  .stats-subtitle {
    font-size: 12px;
    line-height: 15px;
    font-weight: 600;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #757474;
  }
  .stats-unit {
    font-size: 11px;
    text-transform: uppercase;
  }
  .user-stats {
    background-color: #121212;
    // padding: 0 20px;
    display: grid;
    grid-template-columns: 3fr 2fr;
    height: 88px;
    overflow-x: auto;
    // margin-top: 33px;
    .stats-section-1 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
      .stats-item {
        border-right: 1px solid rgba(255, 255, 255, 0.2);
      }
    }
    .stats-section-2 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      .stats-item {
        border-right: 1px solid rgba(255, 255, 255, 0.2);
      }
      .stats-item:last-child {
        border-right: none;
      }
    }
    .stats-item {
      font-size: 22px;
      line-height: 24px;
      font-weight: bold;
      padding: 0 20px;
      .stats-subtitle {
        margin-bottom: 6px;
      }
      .stats-value {
        white-space: nowrap;
      }
    }
  }
}
@media screen and (max-width: 499px) {
  .lifetime-stats {
    .user-stats {
      height: auto;
      grid-template-columns: 1fr;
      .stats-section-1 {
        padding-top: 12px;
        .stats-subtitle {
          line-height: 12px;
        }
        .stats-item:last-child {
          border-right: none;
        }
      }
      .stats-section-2 {
        padding-bottom: 12px;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        .stats-item {
          padding-top: 8px;
        }
      }
      .stats-item {
        height: 54px;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>
